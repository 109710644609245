import React from 'react'
import config from 'config'

import { SkipContent } from 'components/ada'

import LogoLink from 'compositions/LogoLink/LogoLink'
import { MainHeader, Search, ChatAssistance, Cart, UserNavWrapper, TopBarBanner, QueueCounter } from 'compositions/Header/components'

import { Nav, SupNav, UserNav, LocaleNav } from './components'


export type DesktopProps = {
  withBanner?: boolean
  withSupNav?: boolean
  loginWithBackRedirect?: boolean
  withQueueCounter?: boolean
  withChatAssistance?: boolean
  logoLink?: string
}

const Desktop: React.FunctionComponent<DesktopProps> = (props) => {
  const { withBanner = true, withSupNav = true, withQueueCounter, loginWithBackRedirect, logoLink, withChatAssistance } = props
  const isLocaleNavVisible = config.runEnv !== 'prod'

  return (
    <>
      <SkipContent contentId="main" />
      {
        withBanner && (
          <TopBarBanner />
        )
      }
      {
        withSupNav && (
          <SupNav />
        )
      }
      <MainHeader size={withQueueCounter ? 1312 : undefined}>
        <LogoLink color="white" to={logoLink} />
        <Nav />
        <div className="grid grid-flow-col items-center gap-8">
          {
            isLocaleNavVisible && (
              <LocaleNav />
            )
          }
          {
            withChatAssistance && (
              <ChatAssistance />
            )
          }
          {
            withQueueCounter && (
              <QueueCounter />
            )
          }
          <Cart />
          <Search />
          <UserNavWrapper withRedirect={loginWithBackRedirect}>
            <UserNav />
          </UserNavWrapper>
        </div>
      </MainHeader>
    </>
  )
}


export default Desktop
