import React from 'react'
import { useDevice } from 'device'

import { useStrapiWSTONavigationData } from 'modules/brands'

import BannerBase from 'compositions/banners/BannerBase/BannerBase'


type StrapiWSTONavigationBannerProps = {
  onClick?: () => void
  placement: BrandsModule.StrapiWSTONavigationBannerPlacement
  'data-testid'?: string
}

// TODO: Merge with StrapiNavigationBanner after strapi banners refactoring — added on 17-04-2023 by algeas
const StrapiWSTONavigationBanner: React.FC<StrapiWSTONavigationBannerProps> = (props) => {
  const { placement, onClick, 'data-testid': dataTestId = 'brandsBanner' } = props

  const { isMobile } = useDevice()

  const strapiWSTONavigationData = useStrapiWSTONavigationData()
  const strapiWSTONavigationBannerData = strapiWSTONavigationData?.banners?.[placement]

  if (!strapiWSTONavigationBannerData) {
    return null
  }

  const { to, name } = strapiWSTONavigationBannerData

  const { background } = strapiWSTONavigationBannerData

  return (
    <BannerBase
      to={to}
      bgImage={isMobile ? background?.mobile : background?.desktop}
      content="Featured brand"
      data-testid={dataTestId}
      onClick={onClick}
      sizes="560px"
      trackParams={{
        productBrand: name,
      }}
    />
  )
}

export default StrapiWSTONavigationBanner
