export default {
  title: {
    en: 'The scents you want,\nminus the ingredients\nyou don\'t',
  },
  text: {
    en: 'These clean fragrances are\nintoxicating to the senses, but\nuse completely non-toxic\nformulations.',
  },
  buttonTitle: {
    en: 'Shop clean scents',
  },
}
