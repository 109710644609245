import React from 'react'
import { useFt } from 'hooks'
import { constants } from 'helpers'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useStrapiNavigationBanner } from 'modules/banners'

import { Bone } from 'components/layout'

import TheEditBanner from 'compositions/banners/TheEditBanner/TheEditBanner'
import {
  QuizBanner,
  CasesBanner,
  BrandsBanner,
  GiftSubscriptionBanner,
} from 'compositions/Header/view/Desktop/components/banners'
import BannerBase from 'compositions/banners/BannerBase/BannerBase'
import CleanFragrancesBanner from 'compositions/banners/CleanFragrancesBanner/CleanFragrancesBanner'


type StrapiNavigationBannerProps = {
  placement: 'perfumes' | 'colognes' | 'discover' | 'search' | 'gifts' | 'shop'
  position?: number
  onClick?: () => void
}

const StrapiNavigationBanner: React.FunctionComponent<StrapiNavigationBannerProps> = (props) => {
  const { placement, position, onClick } = props

  const isTheEditEnabled = useFt(constants.features.theEdit)
  const isStrapiBannersEnabled = useFt(constants.features.strapiBanners)
  const isGiftSubscriptionEntrypointsEnabled = useFeatureIsOn(constants.features.giftSubscriptionEntrypoints)
  const { isFetching, bannerData } = useStrapiNavigationBanner({ placement, position, onClick, skip: !isStrapiBannersEnabled })

  if (isFetching) {
    return (
      <Bone className="bg-light-beige" h={296} />
    )
  }

  if (!isStrapiBannersEnabled || !bannerData) {
    if (placement === 'perfumes' || placement === 'colognes') {
      return (
        <BrandsBanner placement={placement} onClick={onClick} />
      )
    }

    if (placement === 'discover') {
      return (
        <QuizBanner onClick={onClick} />)
    }

    if (placement === 'gifts' && isGiftSubscriptionEntrypointsEnabled) {
      return (
        <GiftSubscriptionBanner onClick={onClick} />
      )
    }

    if (placement === 'shop') {
      const BannerComponent = isTheEditEnabled ? TheEditBanner : CasesBanner

      return (
        <BannerComponent onClick={onClick} />
      )
    }

    if (placement === 'search') {
      return isTheEditEnabled
        ? <TheEditBanner placement="Search" data-testid="searchBanner" />
        : <CleanFragrancesBanner />
    }

    return null
  }

  if (!bannerData) {
    return null
  }

  const href = bannerData.isExternalLink ? bannerData.to : null

  return (
    <BannerBase
      to={bannerData.to}
      href={href}
      bgImage={bannerData.bgImage}
      content={null}
      sizes="560px"
      onClick={onClick}
      trackParams={bannerData.trackParams}
      data-testid="brandsBanner" // TODO: Replace test-id after consulting with QA with a more semantically correct one — added on 12–03–2025 by algeas
    />
  )
}


export default StrapiNavigationBanner
