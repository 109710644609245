import React from 'react'
import cx from 'classnames'
import links from 'links'
import { track } from 'analytics'

import { Href } from 'components/navigation'
import { Button } from 'components/inputs'
import { BackgroundImage, Text } from 'components/dataDisplay'

import messages from './messages'
import bgImage from './images/bg.jpg'


const TRACK_CLEAN_FRAGRANCES_BANNER_CLICK = () => {
  track('Banner click', {
    placement: 'Search',
    content: 'Clean fragrances',
    link: links.clean.perfumes,
    action: 'link',
  })
}

const CleanFragrancesBanner: React.FunctionComponent = () => (
  <Href to={links.clean.perfumes} data-testid="searchBanner" onClick={TRACK_CLEAN_FRAGRANCES_BANNER_CLICK}>
    <BackgroundImage
      className={cx('bg-gray-70 p-32')}
      imageClassName="object-left"
      src={bgImage}
      sizes="560px"
      style={{ height: '296rem' }}
    >
      <Text
        className="whitespace-pre-line"
        style="h7"
        message={messages.title}
      />
      <Text
        className="mt-16 whitespace-pre-line"
        style="p3"
        color="white"
        message={messages.text}
      />
      <Button
        className="mt-16"
        title={messages.buttonTitle}
        size={48}
        style="primary"
        tabIndex={-1}
        width={240}
      />
    </BackgroundImage>
  </Href>
)


export default CleanFragrancesBanner
